
table {
  border-collapse: collapse;
}

p {
  line-height: 1.5;
}
th,
td {
  padding: 6px 13px;
  border: 1px solid #dfe2e5;
}
@media only screen and (max-width: 767px) {
  td {
    padding: 0px 0px;
    border: 1px solid #dfe2e5;
    font-size:10px;
  }
}
table tr:nth-child(2n) {
  background: #f6f8fa;
}
.reactMarkDown {
 background-color: 'red';
 font-size: 5px;
}