.bouncing-loader {
    display: flex;
    justify-content: center;
  }
  
  .bouncing-loader > div {
    width: 5px;
    height: 5px;
    margin:3px;
    border-radius: 50%;
    background-color: #a3a1a1;
    opacity: 1;
    animation: bouncing-loader 0.6s infinite alternate;
  }
  
  @keyframes bouncing-loader {
    to {
      opacity: 0.1;
      transform: translateY(-5px);
    }
  }
  
  .bouncing-loader > div:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .bouncing-loader > div:nth-child(3) {
    animation-delay: 0.4s;
  }